import axios from 'axios'
import { Toast } from 'vant';

const service = axios.create({
    timeout: 7000,
})

// 请求拦截器
// 需要租户ID
service.interceptors.request.use(
    config => {
        var token;
        if (localStorage.getItem('token')) {
            token = localStorage.getItem('token');
        }
        config.headers = {
            'Authorization': token,
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'TEN-ID': 1
        };
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        if (response.status == 200) {
            const res = response.data;
            if (res.state == 'fail' && res.msg == 'NEED_MODIFY_PROFILE') {
                Toast.fail('请先绑定新生');
                location.href="/me/profile";
                // routers.push({ path: "/me/profile", query: {} });
            } else {
                return res;
            }
        }
    },
    error => {
        if (error.response.status == 401) {
            // Toast.fail('请重新登录');
        }
    }
)
export default service;