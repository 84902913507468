import request from "@/kits/request";
const imgsUrl = "//i.gxufl.com/upload/reg_system_imgs";
const checkInUrl = "https://yxcheckin.gxufl.com/index?id=";

const baseUrl = "https://yxjk.gxufl.com/api/app";
// const baseUrl = "http://127.0.0.1:88/api/app";
import qs from "qs";
export function getImgsUrl() {
  return imgsUrl;
}
export function getBaseUrl() {
  return baseUrl;
}
export function getCheckInUrl() {
  return checkInUrl;
}
export function login(parameter) {
  return request({
    url: baseUrl + "/login",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function wechatCallback(parameter) {
  return request({
    url: baseUrl + "/login/wechat/callback",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function privacyPolicy(parameter) {
  return request({
    url: baseUrl + "/index/privacyPolicy",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function userAgreement(parameter) {
  return request({
    url: baseUrl + "/index/userAgreement",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function redirect(parameter) {
  return request({
    url: baseUrl + "/login/wechat/redirect",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function sendSms(parameter) {
  return request({
    url: baseUrl + "/login/sendSms",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function commonUpload(parameter) {
  return request({
    url: baseUrl + "/common/upload",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: parameter,
  });
}
export function userProfile(parameter) {
  return request({
    url: baseUrl + "/user/profile",
    method: "post",
    data: qs.stringify(parameter),
  });
}

export function findUserProfile(parameter) {
  return request({
    url: baseUrl + "/userProfile/find",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function userStep(parameter) {
  return request({
    url: baseUrl + "/user/step",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function swiperList(parameter) {
  return request({
    url: baseUrl + "/swiper/list",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function adList(parameter) {
  return request({
    url: baseUrl + "/swiper/adList",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function artPage(parameter) {
  return request({
    url: baseUrl + "/art",
    method: "post",
    data: qs.stringify(parameter),
  });
}

export function artDetail(parameter) {
  return request({
    url: baseUrl + "/art/detail",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function userProfileSaveOrUpdate(parameter) {
  return request({
    url: baseUrl + "/userProfile/saveOrUpdate",
    method: "post",
    data: qs.stringify(parameter),
  });
}
//fee
export function fee(parameter) {
  return request({
    url: baseUrl + "/fee",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function feePay(parameter) {
  return request({
    url: baseUrl + "/fee/pay",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function feeCheck(parameter) {
  return request({
    url: baseUrl + "/fee/check",
    method: "post",
    data: qs.stringify(parameter),
  });
}
//visit
export function visit(parameter) {
  return request({
    url: baseUrl + "/visit",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function insertVisit(parameter) {
  return request({
    url: baseUrl + "/visit/insert",
    method: "post",
    data: qs.stringify(parameter),
  });
}
//delay req
export function delayReq(parameter) {
  return request({
    url: baseUrl + "/delayReq",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function insertDelayReq(parameter) {
  return request({
    url: baseUrl + "/delayReq/insert",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function cancelDelayReq(parameter) {
  return request({
    url: baseUrl + "/delayReq/cancel",
    method: "post",
    data: qs.stringify(parameter),
  });
}
//major req
export function majorReq(parameter) {
  return request({
    url: baseUrl + "/majorReq",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function orgListForMajorReq(parameter) {
  return request({
    url: baseUrl + "/majorReq/orgList",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function insertMajorReq(parameter) {
  return request({
    url: baseUrl + "/majorReq/insert",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function cancelMajorReq(parameter) {
  return request({
    url: baseUrl + "/majorReq/cancel",
    method: "post",
    data: qs.stringify(parameter),
  });
}
//fee req
export function feeReq(parameter) {
  return request({
    url: baseUrl + "/feeReq",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function insertFeeReq(parameter) {
  return request({
    url: baseUrl + "/feeReq/insert",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function cancelFeeReq(parameter) {
  return request({
    url: baseUrl + "/feeReq/cancel",
    method: "post",
    data: qs.stringify(parameter),
  });
}
//hostel
export function hostelTree(parameter) {
  return request({
    url: baseUrl + "/hostel/hostelTree",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function hostel(parameter) {
  return request({
    url: baseUrl + "/hostel",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function insertHostel(parameter) {
  return request({
    url: baseUrl + "/hostel/insert",
    method: "post",
    data: qs.stringify(parameter),
  });
}
// fulltime
export function fullTimeReq(parameter) {
  return request({
    url: baseUrl + "/fullTimeReq",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function insertFullTimeReq(parameter) {
  return request({
    url: baseUrl + "/fullTimeReq/insert",
    method: "post",
    data: qs.stringify(parameter),
  });
}
// hostel fee req
export function hostelFeeReq(parameter) {
  return request({
    url: baseUrl + "/hostelFeeReq",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function insertHostelFeeReq(parameter) {
  return request({
    url: baseUrl + "/hostelFeeReq/insert",
    method: "post",
    data: qs.stringify(parameter),
  });
}
export function cancelHostelFeeReq(parameter) {
  return request({
    url: baseUrl + "/hostelFeeReq/cancel",
    method: "post",
    data: qs.stringify(parameter),
  });
}
